<template>
  <v-row class="fill-height">
    <v-col>
      <v-card>
        <v-card-title>
          <v-icon left>mdi-account-group</v-icon>
          Usuários
          <v-spacer></v-spacer>
          <v-text-field
            v-model="userPagination.search"
            class="mr-3"
            append-icon="mdi-magnify"
            label="Buscar"
            placeholder="Informe o nome do usuário"
            single-line
            hide-details
            @keydown.enter="buscar()"
            @click:append="buscar()"
          />
          <v-spacer></v-spacer>
          <v-switch
            v-model="users.active"
            :label=" `Exibindo ${users.active? 'Ativos': 'Inativos'}`"
            single-line
            hide-details
            class="mr-3"
            style="width: 220px"
            @change="buscar()"
          ></v-switch>
          <v-btn color="#3D1159" dark :to="{ name: 'usersCreate', params: {} }">
            <v-icon left>mdi-plus</v-icon>
            Adicionar
          </v-btn>
        </v-card-title>
        <v-alert type="warning" outlined v-show="!users.items"
        >Nenhum usuário encontrado
        </v-alert>
        <v-data-table
          :headers="headers"
          :items="users.items"
          :options.sync="options"
          :server-items-length="users.totalItems"
          item-key="id"
          class="elevation-1"
          v-show="users.items"
          :footer-props="{
                        'items-per-page-options': [10, 25, 50, 75, 100]
                      }"
        >
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#3D1159"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  :to="{ name: 'user', params: { id: item.id } }"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>Visualizar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";
import {mask} from "vue-the-mask";

import {USERS_GET, USER_SEARCH} from "@/store/actions/user.type";
import {SET_USER_PAGINATION, USER_RESET_STATE} from "@/store/mutations/user.type";

export default {
  name: "ListarUsuarios",
  directives: {mask},
  props: {
    source: String,
  },

  components: {},
  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    await store.dispatch(USER_RESET_STATE);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(USER_RESET_STATE),
      store.dispatch(USERS_GET),
    ]).then(() => {
      next();
    });
  },
  data: () => ({
    options: {},
    selected: [],
    headers: [
      {text: "Nome", align: "left", value: "name"},
      // { text: "Instituição", value: "instituicao" },
      {text: "E-mail", value: "email"},
      {text: "Usuário", value: "username"},
      {text: "Telefone", value: "phone"},
      {text: "Editar", value: "actions", sortable: false},
    ],

    formIsValid: false,

    textRules: [
      (v) => !!v || "Este campo é obrigatório",
      //v => v > 0 || "Esta campo deve ter um valor maior que zero."
    ],
   
  }),
  methods: {
    //Methods Lista Usuarios
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.dados.slice();
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    buscar() {
      const {sortBy, sortDesc, itemsPerPage, page} = this.options

      store.commit(SET_USER_PAGINATION, {
        page: page,
        itemsPerPage: itemsPerPage,
        orderBy: sortBy,
        sortDesc: sortDesc[0]
      })
      store.dispatch(USER_SEARCH);
    },

    reset() {
      this.$refs.formUsuario.reset();
    },
  },
  mounted() {
  },
  watch: {
    options: {
      handler() {
        this.buscar()
      },
      deep: true,
    },
  },
  computed: {
    // ...mapState({
    //   courses: state => state.
    // })

    ...mapGetters(["users", "checkUsers", "userPagination"]),
  },
};
</script>
