var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"fill-height"},[_c('v-col',[_c('v-card',[_c('v-card-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-group")]),_vm._v(" Usuários "),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-3",attrs:{"append-icon":"mdi-magnify","label":"Buscar","placeholder":"Informe o nome do usuário","single-line":"","hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.buscar()},"click:append":function($event){return _vm.buscar()}},model:{value:(_vm.userPagination.search),callback:function ($$v) {_vm.$set(_vm.userPagination, "search", $$v)},expression:"userPagination.search"}}),_c('v-spacer'),_c('v-switch',{staticClass:"mr-3",staticStyle:{"width":"220px"},attrs:{"label":("Exibindo " + (_vm.users.active? 'Ativos': 'Inativos')),"single-line":"","hide-details":""},on:{"change":function($event){return _vm.buscar()}},model:{value:(_vm.users.active),callback:function ($$v) {_vm.$set(_vm.users, "active", $$v)},expression:"users.active"}}),_c('v-btn',{attrs:{"color":"#3D1159","dark":"","to":{ name: 'usersCreate', params: {} }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Adicionar ")],1)],1),_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(!_vm.users.items),expression:"!users.items"}],attrs:{"type":"warning","outlined":""}},[_vm._v("Nenhum usuário encontrado ")]),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.users.items),expression:"users.items"}],staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.users.items,"options":_vm.options,"server-items-length":_vm.users.totalItems,"item-key":"id","footer-props":{
                      'items-per-page-options': [10, 25, 50, 75, 100]
                    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#3D1159","icon":"","to":{ name: 'user', params: { id: item.id } }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizar")])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }